import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ExamLogin from './pages/ExamLogin';
import Instructions from './pages/Instructions';
import Exam from './pages/Exam';
import User from './pages/User';
import Login from './pages/Login';
import Signup from './pages/Signup';
import PrivateRoute from './PrivateRoute';
import ViewTestsByCategory from './pages/ViewTestsByCategory';
import TestResult from './pages/TestResult';
import Subscribe from './pages/Subscribe';
import ForgotPassword from './pages/ForgotPassword';


const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute element={<Home />} />,
  },
  {
    path: "/viewtestbycategory/:categoryname",
    element: <PrivateRoute element={<ViewTestsByCategory />} />,
  },
  {
    path: "/exam-login/:testId",
    element: <PrivateRoute element={<ExamLogin />} />,
  },
  {
    path: "/instructions/:testId",
    element: <PrivateRoute element={<Instructions />} />,
  },
  {
    path: "/exam/:testId",
    element: <PrivateRoute element={<Exam />} />,
  },
  {
    path: "/user/result/:usertestid",
    element: <PrivateRoute element={<TestResult />} />,
  },
  {
    path: "/user/:active",
    element: <PrivateRoute element={<User />} />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import './ViewTestByCategory.css';
import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ViewTestsByCategory = () => {
    const { categoryname } = useParams();
    const [categories, setCategories] = useState([]);
    const [latestTests, setLatestTests] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/user/get-all-categories`, {
                    headers: {
                        Authorization: `${localStorage.getItem('token')}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setCategories(data);
                } else {
                    console.error('Error fetching categories');
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchLatestTestsByCategory = async () => {
            if (categoryname) {
                try {
                    const response = await fetch(`${apiUrl}/api/test/latest-tests-by-category?category=${categoryname}`, {
                        headers: {
                            Authorization: `${localStorage.getItem('token')}`,
                        },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setLatestTests(data);
                        console.log(data)
                    } else {
                        console.error('Error fetching latest tests by category');
                    }
                } catch (error) {
                    console.error('Error fetching latest tests by category:', error);
                }
            }
        };

        fetchLatestTestsByCategory();
    }, [categoryname]);

    const handleChange = (event) => {
        navigate(`/viewtestbycategory/${event.target.value}`);
    };

    const handleSearchChange = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length > 0) {
            try {
                const response = await fetch(`${apiUrl}/api/test/search-tests-by-category?name=${query}&category=${categoryname}`, {
                    headers: {
                        Authorization: `${localStorage.getItem('token')}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setSearchResults(data);
                    console.log(data)
                } else {
                    console.error('Error searching tests by category');
                }
            } catch (error) {
                console.error('Error searching tests by category:', error);
            }
        } else {
            setSearchResults([]);
        }
    };


    const checkStartDateTime = (test) => {
        // Destructure test properties
        const { startsAtDate, startsAtTime, _id } = test;
    
        // Combine startsAtDate and startsAtTime into a single Date object
        const testStartDateTime = new Date(`${startsAtDate}T${startsAtTime}:00`);
    
        // Get the current date and time
        const currentDateTime = new Date();
    
        console.log('Test Start DateTime:', testStartDateTime);
        console.log('Current DateTime:', currentDateTime);
    
 
    
        // Check if the current date and time is greater than or equal to the test start date and time
        if (currentDateTime >= testStartDateTime) {
            // Navigate to the exam login page
            navigate(`/exam-login/${_id}`);
        }
        else{
            toast.warn("Test isn't started yet");
        }
    };


    return (
        <div className="viewtestbycategory">
            <Navbar />
            <div className="row">
                <div className="inputbox">
                    <input
                        placeholder="Search by test name in this category"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <CiSearch size={30} />
                </div>
                <div className="categories">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Exam Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={categoryname}
                            label="Exam Type"
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category.name}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>


            {searchResults.length > 0 ? (
                <div className='results'>
                    <h2>Showing Search Results for " {searchQuery} " in " {categoryname} "</h2>
                    <div className='allTests'>
                        {searchResults.map((test) => (
                            <table className='testCard' key={test._id}
                                onClick={() => {
                                    navigate(`/exam-login/${test._id}`)
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td> <p className='label'>Name</p></td>
                                        <td><p className='testName'>{test.name}</p></td>
                                    </tr>
                                    <tr>
                                        <td> <p className='label'>Duration</p></td>
                                        <td><p className='testDuration'>{test.time}</p></td>
                                    </tr>

                                    {
                                        test.startsAtDate.length > 1 && test.startsAtTime.length > 1 && (
                                            <tr>
                                                <td> <p className='label'>Starts At</p></td>
                                                <td> <p className='startsAt'>{test.startsAtTime} on {test.startsAtDate}</p></td>
                                            </tr>
                                        )

                                    }
                                    <tr>
                                        <td><p className='label'>Subjects</p></td>
                                        <td><p className='subjects'>{test.subjects.join(', ')}</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className='label'>Status</p></td>
                                        <td><p className='status'>{test.status}</p></td>
                                    </tr>
                                </tbody>

                            </table>
                        ))}
                    </div>
                </div>
            ) :
                (
                    <div className='results'>
                        <h2>" Latest Tests in {categoryname} "</h2>
                        <div className='allTests'>
                            {latestTests.map((test) => (
                                <table className='testCard' key={test._id} onClick={() => {
                                    checkStartDateTime(test);
                                   
                                }}>
                                    <tbody>
                                        <tr>
                                            <td> <p className='label'>Name</p></td>
                                            <td><p className='testName'>{test.name}</p></td>
                                        </tr>
                                        <tr>
                                            <td> <p className='label'>Duration</p></td>
                                            <td><p className='testDuration'>{test.time}</p></td>
                                        </tr>

                                        {
                                            test.startsAtDate.length > 1 && test.startsAtTime.length > 1 && (
                                                <tr>
                                                    <td> <p className='label'>Starts At</p></td>
                                                    <td> <p className='startsAt'>{test.startsAtTime} on {test.startsAtDate}</p></td>
                                                </tr>
                                            )

                                        }
                                        <tr>
                                            <td><p className='label'>Subjects</p></td>
                                            <td><p className='subjects'>{test.subjects.join(', ')}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className='label'>Status</p></td>
                                            <td><p className='status'>{test.status}</p></td>
                                        </tr>
                                    </tbody>

                                </table>
                            ))}
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default ViewTestsByCategory;

import React, { useEffect, useMemo, useState } from 'react';
import Navbar from '../components/Navbar';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [init, setInit] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [sendingOtp, setSendingOtp] = useState(false)


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/user/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, otp }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Password Change successfull! You can now log in.');
        setErrorMessage('');
        
        setEmail('');
        setPassword('');

        navigate('/login')

      } else {
        setErrorMessage(data.msg || 'Password Change failed. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
      setSuccessMessage('');
    }
  };

  const sendOtp = async (event) => {
    event.preventDefault();

    setSendingOtp(true);

    if (email.length === 0) {
      toast.error("Please provide email");
      setSendingOtp(false);
      return;
    }

    try {
      const response = await fetch(apiUrl + '/api/user/sendotp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('OTP sent');
      } else {
        toast.error(data.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('An error occurred while sending OTP');
    } finally {
      setSendingOtp(false);
    }
  };



  useEffect(() => {
    initParticlesEngine(async (engine) => {
        await loadSlim(engine);
    }).then(() => {
        setInit(true);
    });
}, []);

const particlesLoaded = (container) => {
    console.log(container);
};

const options = useMemo(
    () => ({
        background: {
            color: {
                value: "#000000",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 6,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 80,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
    }),
    [],
);


  return (
    <div className='loginpage'>
      <Navbar />

      <div className='particleBox'>
        {init && (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        )}
      </div>

      <div className='login-form'>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
       
          <label htmlFor='email'>Email:</label>
          <div className='row'>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {
              sendingOtp ?
                <button
                  type='button'
                  disabled
                >Sending...</button>
                :
                <button
                  type='button'
                  onClick={sendOtp}
                >Send Otp</button>
            }
          </div>
          <label htmlFor='password'>New Password:</label>
          <input
            type='password'
            id='password'
            name='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor='password'>Otp:</label>
          <input
            type='password'
            id='otp'
            name='otp'
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          {errorMessage && <p className='error-message'>{errorMessage}</p>}
          {successMessage && <p className='success-message'>{successMessage}</p>}
          <p>Don't have an account? <a onClick={() => {
            navigate('/signup')
          }}>Signup</a></p>
          <button type='submit'>Change Password</button>
        </form>

      </div>
    </div>
  );
};

export default ForgotPassword;

import React from 'react';
import { MdOutlineMonitor } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import './ExamTopbar.css'; // Adjust the path as per your directory structure

const ExamTopbar = ({timeRemaining,candidateName,testName}) => {
  return (
    <div className="ExamTopbarc1">
      <div className="ExamTopbarc11">
        <div className="ExamTopbariconBox"><FaUser size={80} /></div>
        <div className="ExamTopbarc1Items">
          <p>Candidate Name: <span>[ {candidateName} ]</span></p>
          <p>Exam Name: <span>[ {testName} ]</span></p>
          <p>Remaining Time: <span>[ {timeRemaining} ]</span></p>
        </div>
      </div>
    </div>
  )
}

export default ExamTopbar;

import React, { useEffect, useState ,useMemo} from 'react';
import Navbar from '../components/Navbar';
import './Subscribe.css'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const paykey = process.env.REACT_APP_RazorPay_KEY_ID;

const Subscribe = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [promocode, setPromoCode] = useState('');
    const [subscriptionPrice, setSubscriptionPrice] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [discount, setDiscount] = useState('0');
    const [init, setInit] = useState(false);

    useEffect(() => {
        fetchPlatformFee();
    }, []);

    const fetchPlatformFee = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/platform-fee/get-platform-fee`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${localStorage.getItem('token')}`,
                },
            });

            const data = await response.json();

            if (response.ok) {
                setSubscriptionPrice(Math.round(data.fee));
            } else {
                toast.error(data.msg || 'Failed to fetch platform fee');
            }
        } catch (error) {
            console.error('Error fetching platform fee:', error);
            toast.error('Error fetching platform fee');
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(apiUrl + '/api/user/get-user-data', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                navigate('/login');
                console.log(error);
            }
        };

        fetchUserData();
    }, []);

    const handleApplyPromoCode = async (event) => {
        try {
            const response = await fetch(`${apiUrl}/api/subscription/promocode/discount/${promocode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`
                }
            });

            const data = await response.json();

            if (response.ok) {
                setErrorMessage('');
                setDiscount(JSON.stringify(data.discount));
            } else {
                setErrorMessage(data.msg || 'Invalid code');
            }
        } catch (error) {
            setErrorMessage('Invalid code');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let finalAmount = Math.round(subscriptionPrice);
        let discountAmount = Math.round(subscriptionPrice * discount / 100);

        finalAmount = Math.round(finalAmount - discountAmount);

        try {
            const response = await fetch(`${apiUrl}/api/subscription/createorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    amount: Math.round(subscriptionPrice),
                    currency: 'INR',
                    promocode
                }),
            });

            const data = await response.json();

            if (response.ok) {
                const options = {
                    key: paykey,
                    amount: data.amount,
                    currency: data.currency,
                    name: 'Enzyme by PCC',
                    description: 'Subscription to our platform',
                    order_id: data.id,
                    handler: function (response) {
                        afterPayment(response);
                    },
                    prefill: {
                        name: userData.name,
                        email: userData.email
                    },
                    theme: {
                        color: '#F37254'
                    }
                };

                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            } else {
                setErrorMessage('Payment Failed');
            }
        } catch (error) {
            setErrorMessage('Payment Failed');
        }
    };

    const afterPayment = async (payobj) => {
        let finalAmount = Math.round(subscriptionPrice);
        let discountAmount = Math.round(subscriptionPrice * discount / 100);

        finalAmount = Math.round(finalAmount - discountAmount);

        const obj = {
            ...payobj,
            promocode,
            amount: finalAmount,
            date: new Date()
        };

        try {
            const response = await fetch(`${apiUrl}/api/subscription/add1yearsubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}`
                },
                body: JSON.stringify(obj),
            });
            const data = await response.json();

            if (response.ok) {
                toast.success(`Subscription added successfully. Final amount after discount: ${data.finalAmount}`);
                setErrorMessage('');
                navigate('/');
            } else {
                setErrorMessage(data.msg || 'Payment Failed Please Contact +917000896210');
            }
        } catch (error) {
            setErrorMessage('Payment Failed Please Contact +917000896210');
        }
    };



    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);
    
    const particlesLoaded = (container) => {
        console.log(container);
    };
    
    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "#000000",
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 6,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    return (
        <div className='subscriptionpage'>
            <Navbar />
            <div className='subscription-form'>
                <h2>Subscribe to Enzyme</h2>
                <h3>Platform Fee - <span>Rs. {subscriptionPrice} /-</span></h3>
                <form onSubmit={handleSubmit}>
                    <label htmlFor='promocode'>Promo Code:</label>
                    <div className='row'>
                        <input
                            type='text'
                            id='promocode'
                            name='promocode'
                            value={promocode}
                            onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <button
                            onClick={handleApplyPromoCode}
                            type='button'
                        >Apply</button>
                    </div>
                    {errorMessage && <p className='error-message'>{errorMessage}</p>}
                    <p>( Please add a promocode to avail some extra discount )</p>

                    <table className='pricetable'>
                        <tbody>
                            <tr>
                                <td>Platform Fee</td>
                                <td>Rs. {subscriptionPrice}</td>
                            </tr>
                            {
                                discount > 0 &&
                                <tr>
                                    <td>Discount</td>
                                    <td>{discount}%</td>
                                </tr>
                            }
                            {
                                discount > 0 ?
                                    <tr>
                                        <td>Final Amount</td>
                                        <td>Rs. {Math.round(subscriptionPrice - (subscriptionPrice * discount / 100))}</td>
                                    </tr>
                                    :
                                    <tr>
                                        <td>Final Amount</td>
                                        <td>Rs. {subscriptionPrice}</td>
                                    </tr>
                            }
                        </tbody>
                    </table>

                    <button type='submit'>Pay</button>
                </form>
            </div>


            <div className='particleBox'>
                {init && (
                    <Particles
                        id="tsparticles"
                        particlesLoaded={particlesLoaded}
                        options={options}
                    />
                )}
            </div>
        </div>
    );
};

export default Subscribe;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './HomeLeft.css';
import { toast } from 'react-toastify';



const apiUrl = process.env.REACT_APP_API_BASE_URL;

const HomeLeft = () => {
  const navigate = useNavigate();
  const [examCategory, setExamCategory] = useState({
    name: '',
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(apiUrl + '/api/user/get-all-categories', {
          headers: {
            'Authorization': `${localStorage.getItem('token')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error('Error fetching categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (event) => {
    let temp = {
      name: event.target.value,
    };
    setExamCategory(temp);
  };

  return (
    <div className="dailyPracticeProblems">
      <button className="btn1">PCM</button>
      <h2>Welcome to Enzyme by Padma Chemistry Classes</h2>
      <p>
        Elevate Your JEE Preparation with Enzyme!
        Step into the future of exam prep with Padma Chemistry Classes' latest innovation. Tailored for JEE Mains, Advanced, BITSAT, and other entrance exams, Enzyme offers daily practice problems, real exam simulations, and detailed progress tracking.
      </p>
      <div className="examType">
        <div className="form">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Exam Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={examCategory.name}
              label="Exam Type"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category._id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <button
          className="btn2"
          onClick={() => {
            if (examCategory?.name.length > 0) {
              navigate(`/viewtestbycategory/${examCategory.name}`)
            }
            else {
              toast.warn("Please Select a category");
            }
          }}
        >
          Explore
        </button>
      </div>
    </div>
  );
};

export default HomeLeft;

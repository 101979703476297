import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineMonitor } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import ExamLoginC1 from '../components/ExamLoginC1';
import './ExamLogin.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ExamLogin = () => {
  const navigate = useNavigate();
  const { testId } = useParams();
  const [testData, setTestData] = useState(null);

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/test/gettest/${testId}`, {
          headers: {
            Authorization: `${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTestData(data);
        } else {
          console.error('Error fetching test data');
        }
      } catch (error) {
        console.error('Error fetching test data:', error);
      }
    };

    fetchTestData();
  }, [testId]);

  return (
    <div className="examloginc1">
      <div className='c1'><ExamLoginC1 testName={testData?.name}/></div>
      <div className="c2">
        <div className="loginContainer">
          <h1>Login</h1>
          <div className="hr"></div>
          <label>Username</label>
          <input value="xx.xx.xx.xx" disabled />
          <label>Password</label>
          <input value="xx.xx.xx.xx" disabled />
          <button
            onClick={() => {
              navigate('/instructions/'+testId);
            }}
          >
            LOGIN
          </button>
          <span>( Sample login page - Click login)</span>
        </div>
      </div>
    </div>
  );
};

export default ExamLogin;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineMonitor } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import ExamLoginC1 from '../components/ExamLoginC1'; // Adjust the path as per your directory structure
import inst1 from '../assets/instructions1.png'; // Adjust the path as per your directory structure
import './Instructions.css'; // Adjust the path as per your directory structure
import { toast } from 'react-toastify';


const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Instructions = () => {
  const navigate = useNavigate();
  const { testId } = useParams();
  const [isChecked, setIsChecked] = useState(false);

  const [testData, setTestData] = useState(null);

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/test/gettest/${testId}`, {
          headers: {
            Authorization: `${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTestData(data);
        } else {
          console.error('Error fetching test data');
        }
      } catch (error) {
        console.error('Error fetching test data:', error);
      }
    };

    fetchTestData();
  }, [testId]);



  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleButtonClick = () => {
    if (isChecked) {
      navigate('/exam/' + testId);
    }
    else{
      toast.warn('Please check the checkbox')
    }
  };
  return (
    <div className="main">
      <ExamLoginC1 testName={testData?.name} />
      <div className="c2">
        {
          testData &&
          <div className="instructions">
            <h1>Please read the instructions carefully</h1>
            <p className="leftUnderlined">General Instructions:</p>
            <p className="point">1. Total duration of {testData.name} is {testData.time} ( HH:MM )</p>
            <p className="point">2. The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.</p>
            <p className="point">3. The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols</p>
            <div className="leftPadding">
              <img src={inst1} alt="" className='inst1'/>
            </div>
            <p className="point">4. You can click on the &quot;&gt;&quot; arrow which appears to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on &quot;&lt;&quot; which appears on the right side of question window.</p>
            <p className="point">5. You can click on your &quot;Profile&quot; image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.</p>
            <p className="point">6. You can click on
              <img src={'https://nta.ac.in/img/QuizIcons/down.png'} alt='' width={30} height={30} style={{ objectFit: 'contain' }} />
              to navigate to the bottom and
              <img src={'https://nta.ac.in/img/QuizIcons/up.png'} alt='' width={30} height={30} />
              to navigate to top of the question area, without scrolling.
            </p>
            <p className="leftUnderlined">Navigating to a Question:</p>
            <p className="point">7. To answer a question, do the following:</p>
            <div className="leftPadding">
              <p className="point">a. Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.</p>
              <p className="point">b. Click on Save &amp; Next to save your answer for the current question and then go to the next question.</p>
              <p className="point">c. Click on Mark for Review &amp; Next to save your answer for the current question, mark it for review, and then go to the next question.</p>
            </div>
            <p className="leftUnderlined">Answering a Question:</p>

            <p className="point">8. Procedure for answering a multiple choice type question:</p>
            <div className="leftPadding">
              <p className="point">a. To select your answer, click on the button of one of the options.</p>
              <p className="point">b. To deselect your chosen answer, click on the button of the chosen option again or click on the <b>Clear Response</b> button</p>
              <p className="point">c. To change your chosen answer, click on the button of another option</p>
              <p className="point">d. To save your answer, you MUST click on the Save &amp; Next button.</p>
              <p className="point">e. To mark the question for review, click on the Mark for Review &amp; Next button.</p>
            </div>
            <p className="point">9. To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</p>
            <p className="leftUnderlined">Navigating through sections:</p>
            <p className="point">10. Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by clicking on the section name. The section you are currently viewing is highlighted.</p>
            <p className="point">11. After clicking the Save &amp; Next button on the last question for a section, you will automatically be taken to the first question of the next section.</p>
            <p className="point">12. You can shuffle between sections and questions anytime during the examination as per your convenience only during the time stipulated.</p>
            <p className="point">13. Candidate can view the corresponding section summary as part of the legend that appears in every section above the question palette.</p>
            <div className="hr"></div>
            <p className="brown">Please note all questions will appear in your default language. This language can be changed for a particular question later on.</p>
            <div className="hr"></div>

            <p className="point">
              <input type='checkbox'

                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall. I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations
            </p>
            <div className="hr"></div>
            <button
              className="greenbtn"
              onClick={()=>{
                handleButtonClick()
              }}
            
            >
              PROCEED
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default Instructions;

import React, { useEffect, useState } from 'react';
import './UserProfile.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const UserProfile = () => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(apiUrl + '/api/user/get-user-data', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('token')}` // Adjust according to your token storage
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    const { name, email, subscription } = userData;

    return (
        <div className="user-profile">
            <h2>User Profile</h2>
            <div className="user-info">
                <div>
                    <label>Name:</label>
                    <span>{name}</span>
                </div>
                <div>
                    <label>Email:</label>
                    <span>{email}</span>
                </div>
            </div>
            <div className="subscription-info">
                <h3>Subscription Details</h3>
                {subscription ? (
                   <div className="user-info">
                        <div>
                            <label>Status:</label>
                            <span>{subscription.isActive ? 'Active' : 'Inactive'}</span>
                        </div>
                        <div>
                            <label>Expiry Date:</label>
                            <span>{new Date(subscription.expiryDate).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <label>Payment ID:</label>
                            <span>{subscription.paymentId}</span>
                        </div>
                        <div>
                            <label>Promo Code:</label>
                            <span>{subscription.promoCode}</span>
                        </div>
                        <div>
                            <label>Amount:</label>
                            <span>Rs. {subscription.amount.toFixed(2)}</span>
                        </div>
                        <div>
                            <label>Start Date:</label>
                            <span>{new Date(subscription.date).toLocaleDateString()}</span>
                        </div>
                    </div>
                ) : (
                    <p>No subscription information available.</p>
                )}
            </div>
        </div>
    );
};

export default UserProfile;

// src/components/Navbar.tsx
import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { FaRegUserCircle } from "react-icons/fa";
import { CiMenuFries, CiUser } from "react-icons/ci";
import { IoCloseSharp } from "react-icons/io5";

import logo from '../assets/enzyme.png';
import { useNavigate } from 'react-router-dom';
import { verifyToken } from '../utils/authUtils'; // Import the function

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const checkToken = async () => {
            const result = await verifyToken(token);
            if (result.valid) {
                setIsValid(true);
            } else {
                localStorage.removeItem('token');
                setIsValid(false);
            }
        };

        checkToken();
    }, [token]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <nav className='navbar'>
            <div className='left'>
                <img
                    src={logo}
                    alt="Logo"
                    height={100}
                    style={{ objectFit: 'contain' }}
                />
            </div>
            <div className='center'>
                <a href="/" className='link'>Home</a>
                <a href="https://www.padmaclasses.in/index.php" target='_blank' className='link'>About</a>
                <a href="https://www.padmaclasses.in/contact.php" target='_blank' className='link'>Contact</a>
            </div>
            <div className='right'>
                <CiUser className='user' onClick={() => navigate('/user/profile')} />
                {isValid === true ? (
                    <button className='loginButton' onClick={() => handleLogout()}>Logout</button>
                ) : (
                    <button className='loginButton' onClick={() => navigate('/login')}>Login</button>
                )}
            </div>
            <CiMenuFries className='hamburger' onClick={toggleMenu} />

            {menuOpen && (
                <div className='mobileMenu'>
                    <IoCloseSharp className='closeIcon' onClick={toggleMenu} />
                    <div className='mobileMenuContent'>
                        <img
                            src={logo}
                            alt="Logo"
                            width={100}
                            style={{ objectFit: 'contain' }}
                        />
                        <CiUser className='user' onClick={() => navigate('/user/profile')} />

                        <a href="/" className='link'>Home</a>
                        <a href="https://www.padmaclasses.in/index.php" target='_blank' className='link'>About</a>
                        <a href="https://www.padmaclasses.in/contact.php" target='_blank' className='link'>Contact</a>

                        {isValid === true ? (
                            <button className='loginButton' onClick={() => handleLogout()}>Logout</button>
                        ) : (
                            <button className='loginButton' onClick={() => navigate('/login')}>Login</button>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;

// src/utils/authUtils.js
export const verifyToken = async (token) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    if (!token) {
        return { valid: false };
    }

    try {
        const response = await fetch(`${apiUrl}/api/user/verify-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });

        const data = await response.json();

        if (response.ok && data.valid) {
            return { valid: true };
        } else {
            return { valid: false };
        }
    } catch (error) {
        console.error('Error verifying token:', error);
        return { valid: false };
    }
};



export const checkSubscription = async (token) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    if (!token) {
        return { isActive: false };
    }

    try {
        const response = await fetch(`${apiUrl}/api/subscription/checksubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
            },
        });

        const data = await response.json();

        if (response.ok) {
            return {
                isActive: data.isActive,
                expiryDate: data.expiryDate,
                paymentId: data.paymentId,
                promoCode: data.promoCode,
                amount: data.amount,
                date: data.date
            };
        } else {
            return { isActive: false };
        }
    } catch (error) {
        console.error('Error checking subscription:', error);
        return { isActive: false };
    }
};
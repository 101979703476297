import React, { useEffect, useState } from 'react';
import './NewsBulletin.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const NewsBulletin = () => {
  const [newsBulletins, setNewsBulletins] = useState([]);

  useEffect(() => {
    const fetchNewsBulletins = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/newsBulletin` ,{
            headers: {
              'Authorization': `${localStorage.getItem('token')}`,
            },
          });
        if (response.ok) {
          const data = await response.json();
          setNewsBulletins(data);
        } else {
          console.error('Error fetching news bulletins');
        }
      } catch (error) {
        console.error('Error fetching news bulletins:', error);
      }
    };

    fetchNewsBulletins();
  }, []);

  return (
    <div className="news-bulletin">
      <marquee className="news-marquee" direction="left" scrollamount="5">
        {newsBulletins.map((bulletin, index) => (
          <span key={index}>
            {bulletin.description}
            {index < newsBulletins.length - 1 && ' • '}
          </span>
        ))}
      </marquee>
    </div>
  );
};

export default NewsBulletin;

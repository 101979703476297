import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ExamC1 from '../components/ExamTopbar';
import './Exam.css'
import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_BASE_URL;


const Exam = () => {
    const navigate = useNavigate();
    const { testId } = useParams();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [candidateName, setCandidateName] = useState('');
    const [testData, setTestData] = useState(null); // Hook for storing test data
    const [timeStarted, setTimeStarted] = useState("");
    const [duration, setDuration] = useState("")
    // suppose current time is "13:15" and duration is 3:00 hrs -> remaining time is 2:45;
    const [timeRemaining, setTimeRemaining] = useState("");

    const [submit, setIsSubmit] = useState(null);
    const [info, setInfo] = useState({
        notvisited: questions.length,
        notanswered: 0,
        answered: 0,
        markedforreview: 0,
        saveandmarkforreview: 0,
    });
    const [usertestid, setUsertestid] = useState("");



    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/user/get-user-data`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setCandidateName(data.name);
                } else {
                    console.error('Error fetching user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };



        const fetchTestData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/test/gettest/${testId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`,
                    },
                });


                if (response.ok) {
                    const data = await response.json();
                    setTestData(data);


                    let started = new Date();
                    let DurationInHHmm = data.time;
                    const [hours, minutes] = DurationInHHmm.split(":").map(Number);
                    const DurationInSeconds = (hours * 3600) + (minutes * 60);

                    console.log(started);  //Tue Jul 23 2024 16:03:56 GMT+0530 (India Standard Time)
                    console.log(DurationInHHmm); // 02:15
                    console.log(DurationInSeconds);    // 8100



                    setTimeStarted(started);
                    setDuration(DurationInSeconds);
                    setTimeRemaining(DurationInHHmm);




                    const transformedQuestions = data.questions.map((question, index) => ({
                        ...question,
                        status: 'notvisited',
                        totalOptions: 4,
                        userAnswer: null  //for both mcq and oneWord
                    }))

                    setQuestions(transformedQuestions);

                }
                else {
                    console.error('Error fetching test data');
                }
            }
            catch (error) {
                console.error('Error fetching test data:', error);
            }
        }

        fetchUserData();
        fetchTestData();
    }, [testId]);


    const calculateTimeRemaining = (endTime) => {
        const now = new Date();
        const difference = endTime - now;
        if (difference <= 0) return "00:00";

        const hours = Math.floor(difference / 3600000);
        const minutes = Math.floor((difference % 3600000) / 60000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };


    const updateTime = () => {
        const endTime = new Date(timeStarted.getTime() + duration * 1000);
        const remainingTime = calculateTimeRemaining(endTime);

        if (remainingTime === "00:00") {
            setIsSubmit(false);
            toast.warn("Time is up!")
        }

        setTimeRemaining(remainingTime);
    };

    useEffect(() => {
        const intervalId = setInterval(updateTime, 59000); // Update every minute
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [timeStarted, duration]);






    const handleOptionChange = (optionIndex) => {

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].userAnswer = JSON.stringify(optionIndex + 1);
        // console.log(updatedQuestions[currentQuestionIndex].userAnswer);
        setQuestions(updatedQuestions);
    };

    const handleInputChange = (event) => {
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].userAnswer = event.target.value;
        setQuestions(updatedQuestions);
    };

    const isAnswerNotNull = () => {
        let currentQuestion = questions[currentQuestionIndex];
        if (!currentQuestion.userAnswer) {

            return false;
        }
        else {
            return true;
        }
    }
    const changeQuestionStatus = (newStatus) => {
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].status = newStatus;
        setQuestions(updatedQuestions);

        return true;
    }
    const clearAnswer = () => {
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].userAnswer = null;
        setQuestions(updatedQuestions);
        changeQuestionStatus("notanswered");
        return true;
    }

    const handleNextAndBack = (buttonType) => {
        if (buttonType == 'next') {
            if (currentQuestionIndex < questions.length - 1) {
                if (isAnswerNotNull() == false) {
                    changeQuestionStatus("notanswered");
                }
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }
            else {
                // alert('You have already reached the last question');
                toast.warn("You have already reached the last question", {
                    theme: "dark",
                    autoClose: 2000
                })
            }
        }
        else {
            if (currentQuestionIndex > 0) {
                if (isAnswerNotNull() == false) {
                    changeQuestionStatus("notanswered");
                }
                setCurrentQuestionIndex(currentQuestionIndex - 1);
            }
            else {
                toast.warn('You have already reached the first question', {
                    theme: "dark",
                    autoClose: 2000
                });
            }

        }
    }




    function getInfoCounts(questions) {
        let notanswered = 0;
        let answered = 0;
        let markedforreview = 0;
        let saveandmarkforreview = 0;

        questions.forEach(question => {
            switch (question.status.toLowerCase()) {
                case 'notanswered':
                    notanswered++;
                    break;
                case 'answered':
                    answered++;

                    break;
                case 'markedforreview':
                    markedforreview++;

                    break;
                case 'saveandmarkforreview':
                    saveandmarkforreview++;
                    setInfo({
                        ...info,
                        saveandmarkforreview: info.saveandmarkforreview + 1
                    })
                    break;
                default:
                    break;
            }
        });

        let notvisited = questions.length - (notanswered + answered + markedforreview + saveandmarkforreview);

        return {
            notvisited: notvisited,
            notanswered: notanswered,
            answered: answered,
            markedforreview: markedforreview,
            saveandmarkforreview: saveandmarkforreview
        };
    }

    const handleInfoChange = (newInfo) => {
        if (newInfo == "saveandnext") {
            // selected answer must not be null and set question status as answered, then handleNextAndBack("next") 
            console.log(questions[currentQuestionIndex])
            if (isAnswerNotNull() == true) {
                changeQuestionStatus("answered");
                handleNextAndBack("next");
            }
            else {
                toast.error("Please Select an Answer/Option", {
                    theme: "dark",
                    autoClose: 2000
                })
            }
        }
        if (newInfo == "submitexam") {
            // selected answer must not be null and set question status as answered, then handleNextAndBack("next") 
            console.log(questions[currentQuestionIndex])
            if (isAnswerNotNull() == true) {
                changeQuestionStatus("answered");
            }
        }
        if (newInfo == "clear") {
            // make selected answer null 
            clearAnswer();
        }
        if (newInfo == "saveandmarkforreview") {
            // selected answer must not be null and set question status as saveandmarkforreview,

            if (isAnswerNotNull() == true) {
                changeQuestionStatus("saveandmarkforreview");
            }
            else {
                toast.error("Please Select an Answer/Option", {
                    theme: "dark",
                    autoClose: 2000
                })
            }
        }
        if (newInfo == "markforreviewandnext") {
            // selected answer must not be null and set question status as markforreviewandnext, and  then handleNextAndBack("next") 

            if (isAnswerNotNull() == true) {
                changeQuestionStatus("markforreviewandnext");
                handleNextAndBack("next")
            }
            else {
                toast.error("Please Select an Answer/Option", {
                    theme: "dark",
                    autoClose: 2000
                })
            }
        }


        const count = getInfoCounts(questions);
        setInfo(count)
    }


    async function convertToHHMM(isoString) {
        const date = new Date(isoString);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    const handleExamSubmit = async () => {
        // console.log(questions);
        handleInfoChange("submitexam");
        const scoredMarks = questions.reduce((total, question) => {
            if (question.status === "answered") {
                return total + (question.userAnswer.toLowerCase() === question.correctAnswer.toLowerCase() ? question.marks : -question.negativeMarks);
            }
            return total;
        }, 0);
        const maximumMarks = questions.reduce((total, question) => total + question.marks, 0);
        // console.log("Scored Marks: ", scoredMarks);
        // console.log("Total Marks: ", maximumMarks);


        const transformedQuestions = questions.map(question => ({
            _id: question._id,
            userAnswer: question.userAnswer
        }));

        const date = new Date();
        // Extract hours, minutes, and seconds in local time
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        // Format the time as HH:MM:SS
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        const timeStartedinHHmm = await convertToHHMM(timeStarted);
        const saveTestToUser = {
            testId: testId,
            answers: transformedQuestions,
            timeStarted: timeStartedinHHmm,
            timeSubmited: formattedTime,
            marks: scoredMarks,
            totalMarks: maximumMarks
        }

        console.log("saveToUser ", saveTestToUser);


        fetch(apiUrl + '/api/user/savetesttouser', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(saveTestToUser)
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setIsSubmit(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    const handleShowResult = () => {
        setIsSubmit(null);
        navigate('/user/result/' + testId);
    }

    return (
        <div className="exam">
            <ExamC1 candidateName={candidateName} timeRemaining={timeRemaining} testName={testData?.name} />

            {
                questions.length > 0 &&
                <div className="container">
                    <div className="examleft">
                        <div className="questionContainer">
                            <div className="questionContainerR1">
                                <h3>Question {questions[currentQuestionIndex].priority}:</h3>
                                <img src={'https://nta.ac.in/img/QuizIcons/down.png'} alt='' width={30} height={30} objectFit='contain' />
                            </div>

                            <div className="questionContainerHR"></div>

                            <div className="questionContainerR2">
                                <img alt='' src={questions[currentQuestionIndex].image} width={500} height={500} />
                            </div>

                            <div className="questionContainerR3">
                                {questions[currentQuestionIndex].questionType === 'mcq' ? (
                                    Array.from({ length: questions[currentQuestionIndex].totalOptions || 0 }).map((_, index) => (
                                        <div key={index}>
                                            <input
                                                type="radio"
                                                name={`question-${currentQuestionIndex}`}
                                                value={index}
                                                checked={questions[currentQuestionIndex].userAnswer == index + 1}
                                                onChange={() => handleOptionChange(index)}
                                            />
                                            <label>{String.fromCharCode(65 + index)}</label>
                                        </div>
                                    ))
                                ) : (
                                    <input
                                        type="text"
                                        value={questions[currentQuestionIndex].userAnswer || ''}
                                        onChange={handleInputChange}
                                    />
                                )}
                            </div>
                            <div className="questionContainerHR"></div>

                            <div className="questionContainerR1">
                                <h3></h3>
                                <img src={'https://nta.ac.in/img/QuizIcons/up.png'} alt='' width={30} height={30} objectFit='contain' />
                            </div>
                        </div>
                        <div className="btnRow1">
                            <button className="greenbtn" onClick={() => handleInfoChange('saveandnext')}>SAVE & NEXT</button>
                            <button className="whitebtn" onClick={() => handleInfoChange('clear')}>CLEAR</button>
                            <button className="orangebtn" onClick={() => handleInfoChange('saveandmarkforreview')}>SAVE & MARK FOR REVIEW</button>
                            <button className="bluebtn" onClick={() => handleInfoChange('markforreviewandnext')}>MARK FOR REVIEW & NEXT</button>

                        </div>
                        <div className="btnRow2">
                            <div className="backnext">
                                <button className="whitebtn" onClick={() => handleNextAndBack('back')}>&lt;&lt; BACK</button>
                                <button className="whitebtn" onClick={() => handleNextAndBack('next')}>NEXT &gt;&gt;</button>
                            </div>
                            <button className="greenbtn"
                                onClick={() => {
                                    setIsSubmit(false)

                                }}
                            >SUBMIT</button>
                        </div>
                    </div>
                    <div className="examright">
                        <div className="infoContainer">
                            <div className="infoItem">
                                <span className="notvisited">{info.notvisited}</span>
                                <span className="infoLabel">Not Visited</span>
                            </div>
                            <div className="infoItem">
                                <span className="notanswered">{info.notanswered}</span>
                                <span className="infoLabel">Not Answered</span>
                            </div>
                            <div className="infoItem">
                                <span className="answered">{info.answered}</span>
                                <span className="infoLabel">Answered</span>
                            </div>
                            <div className="infoItem">
                                <span className="markedforreview">{info.markedforreview}</span>
                                <span className="infoLabel">Marked For Review</span>
                            </div>
                            <div className="infoItemFull">
                                <span className="saveandmarkforreview">{info.saveandmarkforreview}</span>
                                <span className="infoLabel">Saved & Marked For Review</span>
                            </div>
                        </div>
                        <div className="questionsInfo">
                            {questions.length > 0 && questions.map((item, index) => {

                                let btnType = "notvisited"

                                if (item.status == "notvisited") {
                                    btnType = "notvisited"

                                    if (item.priority == currentQuestionIndex + 1) {
                                        btnType = "notanswered"
                                    }
                                }
                                else if (item.status == "answered") {
                                    btnType = "answered"
                                }
                                else if (item.status == "notanswered") {
                                    btnType = "notanswered"
                                }
                                else if (item.status == "saveandmarkforreview") {
                                    btnType = "saveandmarkforreview"
                                }
                                else if (item.status == "markedforreview") {
                                    btnType = "markedforreview"
                                }

                                // console.log(item.priority, btnType, currentQuestionIndex + 1);

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setCurrentQuestionIndex(item.priority - 1);
                                        }}
                                    >

                                        <span className={btnType}>{item.priority}</span>


                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }

            {submit === true && (
                <div className="modal">
                    <div className="modalContainer">
                        <p className="head">Thank you, Submitted Successfully.</p>
                        <div>
                            <button onClick={() => handleShowResult()}>VIEW RESULT</button>
                        </div>
                    </div>
                </div>
            )}
            {submit === false && (
                <div className="modal">
                    <div className="modalContainer">
                        <p className="head">Exam Summary</p>
                        <div className="infoContainer2">
                            <div className="infoItem2">
                                <span> <b>No. of Questions</b></span>
                                <span>{questions.length}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="infoItem2">
                                <span> <b>Answered</b></span>
                                <span>{info.answered}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="infoItem2">
                                <span> <b>Not <br></br>Answered</b></span>
                                <span>{info.notanswered}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="infoItem2">
                                <span> <b>Marked for<br></br>Review</b></span>
                                <span>{info.markedforreview}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="infoItem2">
                                <span> <b>Answered & Marked for Review <br></br>(will be considered for evaluation)</b></span>
                                <span>{info.saveandmarkforreview}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="infoItem2">
                                <span> <b>Not <br></br> Visited</b></span>
                                <span>{info.notvisited}</span>
                            </div>
                        </div>
                        <p>
                            Are you sure you want to submit for final marking?<br></br>
                            No changes will be allowed after submission.
                        </p>
                        <div>
                            <button onClick={() => {
                                handleExamSubmit()
                            }}>YES</button>
                            <button onClick={() => {
                                if (timeRemaining === "00:00") {
                                    toast.error("Time up! please click Yes to save the exam.")
                                }
                                else {
                                    setIsSubmit(null)
                                }
                            }} disabled={timeRemaining === "00:00"}>NO</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Exam
import React, { useEffect, useState } from 'react';
import { MdOutlineMonitor } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import './ExamLoginC1.css'; // Adjust the path as per your directory structure

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ExamLoginC1 = ({ testName }) => {
  const [candidateName, setCandidateName] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user/get-user-data`, {
          method: 'GET',
          headers: {
            'Authorization': `${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCandidateName(data.name);
        } else {
          console.error('Error fetching user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="examlogintopbar">
      <div className="examlogintopbarc11">
        <div className="iconBox">
          <MdOutlineMonitor size={50} />
        </div>
        <div className="c1Items">
          <p>System Name: <span>[ My System 1 ]</span></p>
          <p><span>[ A Sample Name and Photograph displayed on the screen ]</span></p>
        </div>
      </div>
      <div className="examlogintopbarc11">
        <div className="iconBox">
          <FaUser size={50} />
        </div>
        <div className="c1Items">
          <p>Candidate Name: <span>{candidateName}</span></p>
          <p>Test Name: <span>[ {testName} ]</span></p>
        </div>
      </div>
    </div>
  );
};

export default ExamLoginC1;

import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import './User.css'
import UserProfile from '../components/UserProfile'
import ParticleComponent from '../components/ParticleComponent'
import AllResults from '../components/AllResults'
import { useNavigate, useParams } from 'react-router-dom'
const User = () => {
  const { active } = useParams();
  const navigate = useNavigate();

  // const [active, setActive] = useState('profile')
  return (
    <div className='userpage'>
      <Navbar />

      <ul className='userItems'>
        <li className={active == 'profile' ? 'useritemActive' : 'useritem'} onClick={() => navigate('/user/profile')}>Profile</li>
        <li className={active == 'result' ? 'useritemActive' : 'useritem'} onClick={() => navigate('/user/result')}>Results</li>
      </ul>

      {
        active == 'profile' && <UserProfile />
      }
      {
        active == 'result' && <AllResults />
      }


      <ParticleComponent />
    </div>
  )
}

export default User
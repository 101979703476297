import React, { useEffect, useMemo, useState } from 'react';
import HomeLeft from '../components/HomeLeft';
import HomeRight from '../components/HomeRight';
import Navbar from '../components/Navbar';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
// import img1 from '../assets/temp/1.png';
import './Home.css'
import NewsBulletin from '../components/NewsBulletin';
import Footer from '../components/Footer';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Home = () => {
    const [init, setInit] = useState(false);
    const [imageKey, setImageKey] = useState('');

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    useEffect(() => {
        const fetchImageKey = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/home-poster/get-home-poster`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();
                console.log(data)
                setImageKey(data); // Assuming the response has an imageKey property
            } catch (error) {
                console.error('Error fetching home poster image key:', error);
            }
        };

        fetchImageKey();
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "#000000",
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 6,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    return (
        <div className='home'>
            <Navbar />
            <NewsBulletin />
            <div>
                <div className='particleBox'>
                    {init && (
                        <Particles
                            id="tsparticles"
                            particlesLoaded={particlesLoaded}
                            options={options}
                        />
                    )}
                </div>


                <div className={'homerow'}>
                    <div className={'homeColumn'}>
                        <HomeLeft />
                    </div>
                    <div className={'homeColumn'}>
                        {imageKey ? (
                            <img alt="Home Poster" src={imageKey} className={'homeimg'} />
                        ) : (
                            <p>Loading image...</p>
                        )}
                    </div>
                </div>
            </div>

            <div className='pgbig'>
                <h2>
                    Why Choose
                    <h1>Enzyme?</h1>
                </h2>

                <div className='pgbigIn'>
                    <p><span>Comprehensive Practice:</span> Tackle a wide range of problems that mirror actual exam questions.</p>
                    <p><span>Instant Feedback:</span> Get detailed solutions and insights to improve your understanding.</p>
                    <p><span>Convenient & Flexible:</span> Give Test, anywhere with our user-friendly interface.</p>
                    <p><span>Stay Motivated:</span> Track your progress and see real-time improvements.</p>
                    <h2>Unlock your potential and achieve the scores you've always dreamed of!</h2>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;

// src/components/PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { checkSubscription, verifyToken } from './utils/authUtils'; // Import the function from the correct path
import { toast } from 'react-toastify';

const PrivateRoute = ({ element }) => {
    const [isValid, setIsValid] = useState(null);
    const location = useLocation(); // Hook to get the current location
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const fetchSubscription = async () => {
        console.log('checkingsubscription')
        const token = localStorage.getItem('token');
        if (token) {
            const data = await checkSubscription(token);
            if (data.isActive) {
                // console.log(data);
            } else {
                toast.warn('Subscription is not active or expired.');
                navigate('/subscribe')
            }
        
        } else {
            console.log('Token not found')
        
        }
    };
    useEffect(() => {
        const checkToken = async () => {
            console.log('checking token')
            const result = await verifyToken(token);
            if (result.valid) {
                fetchSubscription();
                setIsValid(true);
            } else {
                localStorage.removeItem('token');
                setIsValid(false);
            }
        };

        checkToken();
    }, [location, token]); // Re-run when location or token changes


    // useEffect(() => {
        

    //     fetchSubscription();
    // }, []);


    if (isValid === null) {
        // While waiting for the token verification, you can show a loading spinner or similar
        return <div>Loading...</div>;
    }

    return isValid ? element : <Navigate to="/login" />;
};

export default PrivateRoute;

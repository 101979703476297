import React, { useEffect, useState } from 'react';
import './AllResults.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AllResults = () => {
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const fetchResults = async () => {
    try {
      const response = await fetch(apiUrl + '/api/user/getusertests', {
        method: 'GET',
        headers: {
          'Authorization': `${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setResults(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  

  useEffect(() => {
    fetchResults();
  }, []);

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Test ID</th>
            <th>Test Name</th>
            <th>Date of Exam</th>
            <th>Marks</th>
            <th>Total Marks</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.testId}
            onClick={()=>{
              navigate('/user/result/'+result.testId);
            }}
            >
              <td>{result.testId}</td>
              <td>{result.testName}</td>
              <td>{new Date(result.dateOfExam).toLocaleDateString()} {new Date(result.dateOfExam).toLocaleTimeString()}</td>
              <td>{result.marks}</td>
              <td>{result.totalMarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllResults;

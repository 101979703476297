import React, { useEffect, useState } from 'react';
import './TestResult.css';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TestResult = () => {
    const navigate = useNavigate();
    const { usertestid } = useParams();
    const [testResult, setTestResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchTestResult = async () => {
            try {
                const response = await fetch(apiUrl + '/api/user/getusertestbyid?id=' + usertestid, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                let data = await response.json();


                // const dateString = data.timeSubmited;
                // const date = new Date(dateString);
                // // Extract hours, minutes, and seconds
                // const hours = date.getUTCHours();
                // const minutes = date.getUTCMinutes();
                // const seconds = date.getUTCSeconds();
                // // Format the time as HH:MM:SS
                // const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                // data.timeSubmited = formattedTime;

                setTestResult(data);
            } catch (err) {
                setError('Failed to fetch test result');
                console.error('Error fetching test result:', err);
            } finally {
                setLoading(false);
            }
        };
        const fetchUserData = async () => {
            try {
                const response = await fetch(apiUrl + '/api/user/get-user-data', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('token')}` // Adjust according to your token storage
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchTestResult();
        fetchUserData();



    }, [usertestid, navigate]);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className='testResult'>
            <Navbar />

            {testResult ? (
                <div className='testResultDetails'>
                    <h3>Result For : {testResult.name}</h3>



                    <table>
                        <tbody>
                            <tr>
                                <td><strong>Candidate Name:</strong></td>
                                <td>{userData.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Candidate Email:</strong></td>
                                <td>{userData.email}</td>
                            </tr>

                            <tr>
                                <td><strong>Type:</strong></td>
                                <td>{testResult.type}</td>
                            </tr>
                            <tr>
                                <td><strong>Duration:</strong></td>
                                <td>{testResult.time}</td>
                            </tr>

                            <tr>
                                <td><strong>Subjects:</strong></td>
                                <td>{testResult.subjects.join(', ')}</td>
                            </tr>
                            <tr>
                                <td><strong>Status:</strong></td>
                                <td>{testResult.status}</td>
                            </tr>
                            <tr>
                                <td><strong>Time Started:</strong></td>
                                <td>{testResult.timeStarted}</td>
                            </tr>
                            <tr>
                                <td><strong>Time Submitted:</strong></td>
                                <td>{testResult.timeSubmited}</td>
                            </tr>
                            <tr>
                                <td><strong>Marks:</strong></td>
                                <td>{testResult.marks}</td>
                            </tr>
                            <tr>
                                <td><strong>Total Marks:</strong></td>
                                <td>{testResult.totalMarks}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Questions & Answers</h4>
                    <ul>
                        {testResult.questions.map((question, index) => (
                            <li key={index}>
                                <img src={question.image} alt={`Question ${index + 1}`} />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><strong>Priority:</strong></td>
                                            <td>{question.priority}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Type:</strong></td>
                                            <td>{question.questionType}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Correct Answer:</strong></td>
                                            <td>{question.correctAnswer}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Marks:</strong></td>
                                            <td>{question.marks}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Negative Marks:</strong></td>
                                            <td>{question.negativeMarks}</td>
                                        </tr>
                                        {question.userAnswer && (
                                            <tr>
                                                <td><strong>User Answer:</strong></td>
                                                <td>{question.userAnswer}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>No test result found</p>
            )}
        </div>
    );
};

export default TestResult;

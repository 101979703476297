import React, { useEffect, useMemo, useState } from 'react';
import Navbar from '../components/Navbar';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [init, setInit] = useState(false);


  const handleSubmit = async (event) => {
    // alert(apiUrl)
    event.preventDefault();

    try {
      //  apiUrl is  backend url ( http://localhost:5000)
      const response = await fetch(`${apiUrl}/api/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Assuming the response contains the token
        localStorage.setItem('token', data.token);
        navigate('/'); // Redirect to a different page after successful login
      } else {
        setErrorMessage(data.msg || 'Invalid username or password');
      }
    } catch (error) {
      setErrorMessage('Invalid email or password'); // Update error message
    }
  };



  // This should be run only once per application lifetime
  useEffect(() => {
      initParticlesEngine(async (engine) => {
          await loadSlim(engine);
      }).then(() => {
          setInit(true);
      });
  }, []);

  const particlesLoaded = (container) => {
      console.log(container);
  };

  const options = useMemo(
      () => ({
          background: {
              color: {
                  value: "#000000",
              },
          },
          fpsLimit: 120,
          interactivity: {
              events: {
                  onClick: {
                      enable: true,
                      mode: "push",
                  },
                  onHover: {
                      enable: true,
                      mode: "repulse",
                  },
              },
              modes: {
                  push: {
                      quantity: 4,
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4,
                  },
              },
          },
          particles: {
              color: {
                  value: "#ffffff",
              },
              links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
              },
              move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                      default: "bounce",
                  },
                  random: false,
                  speed: 6,
                  straight: false,
              },
              number: {
                  density: {
                      enable: true,
                  },
                  value: 80,
              },
              opacity: {
                  value: 0.5,
              },
              shape: {
                  type: "circle",
              },
              size: {
                  value: { min: 1, max: 5 },
              },
          },
          detectRetina: true,
      }),
      [],
  );

  return (
    <div className='loginpage'>
      <Navbar />

      <div className='particleBox'>
        {init && (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        )}
      </div>
      <div className='login-form'>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor='email'>Email:</label>
          <input
            type='text'
            id='email'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor='password'>Password:</label>
          <input
            type='password'
            id='password'
            name='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errorMessage && <p className='error-message'>{errorMessage}</p>}
          <p><a
            onClick={() => {
              navigate('/forgotpassword')
            }}
          >Forgot Password?</a></p>
          <button type='submit'

          >Login</button>

          <p>don't have an accoount? <a
            onClick={() => {
              navigate('/signup')
            }}
          >Register</a></p>
        </form>

      </div>
    </div>
  );
};

export default Login;
